<template>
  <!-- 个人中心 - 账户信息 - 绑定微信-->
  <div class="banquan-dialog">
    <div class="modal edit-img">
      <div class="title">绑定微信<i class="iconfont icon-close" @click="$parent.showBindWx = false"></i></div>
      <div class="wx-img">
        <div class="img">
          <img :src="this.editorUrl + '/api/weixin/bind'" alt="" class="code">
          <img src="@/assets/image/logindialog/bindwx.png" class="bg" alt="">
        </div>
        <p>扫描二维码绑定微信</p>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "@/config/axios/api";

export default {
  name: "Info",
  data () {
    return {
    };
  },
  components: {

  },
  created () {
  },
  methods: {

  }
};
</script>
<style scoped lang="scss">
.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 340px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;

    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .wx-img {
      .img {
        width: 228px;
        height: 228px;
        margin: 35px auto 24px;
        position: relative;

        .bg {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 1;
        }

        .code {
          // width: 180px;
          // height: 180px;
          width: 200px;
          height: 200px;
          position: absolute;
          top: 14px;
          left: 14px;
        }
      }

      p {
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgb(114, 116, 116);
        line-height: 20px;
        margin-bottom: 48px;
      }
    }
  }
}
</style>
