<template>
  <!-- 个人中心 - 账户信息 - 修改昵称-->
  <div class="banquan-dialog">
    <div class="modal edit-img">
      <div class="title">修改昵称<i class="iconfont icon-close" @click="$parent.showEditName = false"></i></div>
      <div class="form">
        <div class="row">
          <input type="text" v-model="nickname" placeholder="请输入名称">
        </div>
        <div class="row">
          <div class="submit" @click="editName()">确认修改</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "@/config/axios/api";

export default {
  name: "Info",
  data () {
    return {
      nickname: '',
    };
  },
  components: {

  },
  created () {
    this.nickname = this.$parent.info.NickName;
  },
  methods: {
    editName () {
      if (!this.nickname.trim()) {
        this.$message.error('请输入昵称');
        return;
      }
      User.editName({ nickname: this.nickname }).then(res => {
        if (res.status == 1) {
          this.$parent.showEditName = false;
          this.$message.success('修改成功');
          this.$parent.getInfo();
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;

    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .form {
      padding: 25px 48px 30px;
      width: 100%;

      .row {
        width: 100%;
        margin-bottom: 18px;
        @include flex(center, space-between);

        input {
          width: 100%;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #DEDEDD;
          padding-left: 23px;

          &:hover,
          &:focus {
            border: 1px solid $activeColor;
          }
        }

        img {
          margin-left: 12px;
          width: 114px;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #EBECF1;
          cursor: pointer;
          flex-shrink: 0;
        }

        .send {
          flex-shrink: 0;
          cursor: pointer;
          margin-left: 12px;
          width: 114px;
          height: 46px;
          background: rgba(52, 171, 255, 0.04);
          border-radius: 4px;
          border: 1px solid #EBECF1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C86FF;
          line-height: 44px;
          text-align: center;
        }

        .submit {
          width: 100%;
          height: 46px;
          cursor: pointer;
          background: #2C86FF;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
        }
      }

      .tip {
        margin-top: 24px;
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;

        a {
          color: $activeColor;
        }
      }
    }
  }
}
</style>
