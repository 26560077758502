<template>
  <!-- 个人中心 - 账户信息 -->
  <div class="info" v-if="info">
    <div class="menu-list-modal">
      <div class="personal-item">
        <p style="line-height: 80px;">头像</p>
        <p>
          <img v-if="info.headimg" :src="info.headimg" class="accout-img">
          <img v-else src="@/assets/image/common/mobile_head.png" class="accout-img">
          <a class="right revise" @click="showEditImg = true" href="javascript:;" style="line-height: 80px;">修改</a>
        </p>
      </div>

      <div class="personal-item vip-info">
        <p>会员等级</p>
        <div class="user-vipinfo" v-if="info.is_sc_vip != 0">
          <div class="vipinfo-item vipinfo-standard" v-if="info.sc_vip_level != 3">
            <span class="vip-type vip-type-standard">{{info.sc_vip_level == 1 ? '月会员' : '普通年会员'}}</span>
            <a class="right revise" id="vip-operate" href="/vip">升级vip</a>
          </div>
          <div class="vipinfo-item vipinfo-pro" v-else>
            <span class="vip-type vip-type-pro">年PLUS会员</span>
            <a class="right revise" href="/vip">去续费</a>
          </div>
        </div>
        <div class="user-vipinfo" v-else>
          <span class="vip-type vip-type-standard">暂未开通</span>
          <a class="right revise" href="/vip">去开通</a>
        </div>
      </div>

      <div class="personal-item">
        <p>昵称</p>
        <p>
          <span>{{info.NickName || '-'}}</span>
          <a class="right revise" @click="showEditName = true">修改</a>
        </p>
      </div>
      <div class="personal-item">
        <p>用户ID</p>
        <p>
          <span id="uu_userid">{{info.UserId}}</span>
        </p>
      </div>
      <div class="personal-item">
        <p>手机</p>
        <p>
          <span>{{info.Mobile || '-'}}</span>
          <a class="right revise bind-phone" v-if="info.Mobile" @click="showEditPhone = true">修改</a>
          <a class="right revise bind-phone" v-else @click="showBindPhone = true">绑定</a>
        </p>
      </div>
      <div class="personal-item">
        <p>微信</p>
        <p>
          <span class="wechat_bind_result">{{info.WeixinNick || '-'}}</span>
          <a class="right revise" id="bind-wx" @click="showBindWx = true">绑定</a>
        </p>
      </div>
      <div class="personal-item">
        <p>微博</p>
        <p>
          <span class="weiboNumber">{{info.WeiboNick || '-'}}</span>
          <a class="right revise" @click="bindWeibo">绑定</a>
        </p>
      </div>
      <div class="personal-item" id="set-pwd-list">
        <p>密码</p>
        <p>
          <span>······</span>
          <a class="right revise" @click="editPass">{{info.Mobile ? '修改' : "设置"}}</a>
        </p>
      </div>
    </div>
    <!--修改头像-->
    <info-img :url="info.headimg" v-if="showEditImg" />
    <bind-phone v-if="showBindPhone" :toEditPass="toEditPass" />
    <edit-pass v-if="showEditPass" />
    <edit-name v-if="showEditName" />
    <edit-phone v-if="showEditPhone" />
    <bind-wx v-if="showBindWx" />


  </div>
</template>
<script>
import { User } from "@/config/axios/api";
import infoImg from "./info/infoImg.vue"
import bindPhone from "./info/bindPhone.vue"
import editPass from "./info/editPass.vue"
import editName from "./info/editName.vue"
import editPhone from "./info/editPhone.vue"
import bindWx from "./info/bindWx.vue"
export default {
  name: "Info",
  data () {
    return {
      showEditImg: false,
      showBindPhone: false,
      showEditPass: false,
      showEditName: false,
      showEditPhone: false,
      showBindWx: false,
      info: null,
      toEditPass: false //绑定完手机号是否去修改密码
    };
  },
  components: {
    infoImg,
    bindPhone,
    editPass,
    editName,
    editPhone,
    bindWx,
  },
  created () {
    this.getInfo();
  },
  methods: {
    getInfo () {
      User.getInfo().then(res => {
        if (res.status == 1) {
          this.info = res.data;
          this.$store.commit('setLoginInfo', res.data)
        }
      })
    },
    bindWeibo: function () {
      var newTab = window.open('about:blank');
      User.bindweibo().then(res => {
        if (res.status == 1) {
          newTab.location.href = res.msg;
        } else {
          this.$message({
            message: res.msg,
            type: 'success',
            duration: '1500'
          });
        }
      })
    }, //绑定微博
    editPass () {
      if (this.info.Mobile) {
        this.showEditPass = true
      } else {
        this.showBindPhone = true;
        this.toEditPass = true;
      }
    }
  }
};
</script>
<style scoped lang="scss">
.info {
  padding: 14px 55px 40px 44px;

  .menu-list-modal {
    width: 100%;
    box-sizing: border-box;

    .right {
      float: right;
    }

    .personal-item {
      width: 100%;
      display: flex;

      p {
        height: 100%;
        font-size: 16px;
        padding: 28px 0;

        &:first-child {
          width: 130px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
        }

        &:last-child {
          width: 100%;
          border-bottom: solid 1px #dedede;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;

          .revise {
            cursor: pointer;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: $activeColor;
          }

          .accout-img {
            width: 75px;
            height: 75px;
            border-radius: 100%;
            background: #ebebeb;
            object-fit: cover;
          }
        }
      }

      .user-vipinfo {
        width: 100%;
        height: 100%;
        font-size: 16px;
        padding: 28px 0;
        border-bottom: 1px solid #dedede;
        font-family: "Microsoft YaHei";
        color: #000;

        .vipinfo-item {
          .vip-type {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1D2020;
          }
        }

        .vipinfo-item:nth-of-type(2) {
          padding-top: 20px;
        }

        .revise {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: $activeColor;
        }
      }

      .duih {
        line-height: 40px;
      }

      .vip-exchange {
        width: 711px;
        height: 100%;
        font-size: 16px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-left: 32px;

        font-family: "Microsoft YaHei";
        color: #000;

        form {
          width: 550px;
          display: inline-block;

          input {
            width: 100%;
            height: 40px;
            background: #ffffff;
            border-radius: 8px;
            border: 1px solid #bab9c8;
            box-sizing: border-box;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #3b3946;
            padding-left: 10px;
            line-height: 40px;

            &:focus {
              border: 1px solid #5a2df5;
            }
          }
        }

        .revise {
          color: #7966ff;
          cursor: pointer;
          line-height: 40px;
        }
      }
    }

    .title_font {
      position: absolute;
      top: 0;
      left: 0;
      //width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      font-weight: 600;
      color: #333333;
      line-height: 25px;
    }

    .h_line {
      position: absolute;
      top: 17px;
      left: 0;
      width: 64px;
      height: 6px;
      background: #cdc6ff;
    }

    .long_h_line {
      width: 812px;
      height: 0;
      border: 1px solid #dedede;
      box-sizing: border-box;
      margin-bottom: 27px;
    }

    .record {
      width: 100%;
      padding-top: 20px;
      margin-bottom: 20px;

      .record_title {
        position: relative;
        width: 100%;
        height: 25px;
      }

      .record_detail {
        display: flex;
        padding-top: 19px;
        align-items: center;

        .detail_item {
          display: flex;
          flex-direction: column;
          align-items: center;

          &:first-child {
            width: 240px;
          }

          &:nth-child(3) {
            width: 310px;
          }

          &:nth-child(3) .item_title {
            width: 112px;
          }

          &:last-child {
            width: 256px;

            .item_title {
              width: 120px;
            }
          }

          &:nth-child(even) {
            width: 3px;
            height: 36px;
            background: #dedede;
          }

          &:nth-child(even) {
            width: 3px;
            height: 36px;
            background: #dedede;
          }

          .item_title {
            width: 80px;
            height: 22px;
            text-align: center;
            font-size: 16px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
          }

          .item_price {
            height: 56px;
            font-family: PingFangSC-Regular;
            font-weight: 400;
            line-height: 56px;

            span {
              &:first-child {
                font-size: 16px;
                color: #333333;
              }

              &:last-child {
                font-size: 40px;
                color: #e54b4b;
              }
            }
          }
        }
      }
    }

    .notice {
      width: 100%;
      margin-bottom: 30px;

      .notice_title {
        position: relative;
        width: 100%;
        height: 25px;
        margin-bottom: 17px;
      }

      .notice_detail {
        width: 617px;
        height: 126px;
        font-size: 14px;
        font-family: PingFang-SC-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 21px;

        span {
          color: #e54b4b;
        }
      }
    }

    .apply_msg {
      width: 100%;

      .apply_msg_title {
        position: relative;
        width: 100%;
        height: 25px;
        margin-bottom: 20px;
      }

      .apply_msg_detail {
        width: 100%;
        margin-bottom: 40px;

        .options_item {
          display: flex;
          align-items: center;
          margin-bottom: 15px;

          &:nth-child(3) {
            margin-bottom: 36px;
          }

          &:nth-child(5) {
            margin-bottom: 55px;
          }

          &:nth-child(7) {
            margin-bottom: 36px;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .options_title {
            width: 98px;
            height: 20px;
            margin-right: 20px;
            text-align: right;
            font-size: 14px;
            font-family: PingFang-SC-Regular;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
          }

          .options_val {
            position: relative;

            input {
              display: block;
              width: 400px;
              height: 29px;
              border: 1px solid #c6c6c6;
              box-sizing: border-box;
              padding-left: 8px;

              &::placeholder {
                font-size: 14px;
                font-family: PingFang-SC-Regular;
                font-weight: 400;
                color: #c6c6c6;
                line-height: 27px;
              }
            }

            .val_font {
              height: 20px;
              font-size: 14px;
              font-family: PingFang-SC-Regular;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }

            .input_fail {
              border: 1px solid #e54b4b;
            }

            textarea {
              display: block;
              padding-left: 8px;
              width: 400px;
              height: 126px;
              border: 1px solid #c6c6c6;
              box-sizing: border-box;

              &::placeholder {
                font-size: 14px;
                font-family: PingFang-SC-Regular;
                font-weight: 400;
                color: #c6c6c6;
                line-height: 27px;
              }
            }

            .hint {
              position: absolute;
              top: 34px;
              left: 0;
              min-height: 17px;
              font-size: 12px;
              font-family: PingFang-SC-Regular;
              font-weight: 400;
              color: #e54b4b;
              line-height: 17px;
            }
          }
        }
      }
    }

    .submit_apply_btn {
      width: 200px;
      height: 40px;
      margin: 0 auto 32px;
      background: #7966ff;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang-SC-Regular;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }

    .order_wrapper {
      width: 100%;

      .order_title {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        padding: 0 40px;
        display: flex;
        align-items: center;

        p {
          height: 100%;
          line-height: 62px;
          font-size: 16px;
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: #333333;

          &:first-child {
            width: 273px;
          }

          &:nth-child(2) {
            width: 160px;
          }

          &:nth-child(3) {
            width: 162px;
          }

          &:nth-child(4) {
            width: 162px;
          }

          &:last-child {
            width: 162px;
          }
        }
      }

      .order_list_wrapper {
        width: 100%;

        .order_list {
          width: 100%;
          box-sizing: border-box;

          .list_item {
            width: 100%;
            padding: 0 40px;
            box-sizing: border-box;
            height: 46px;
            display: flex;
            align-items: center;

            &:nth-child(odd) {
              background: #f4f4f4;
            }

            p {
              height: 100%;
              line-height: 46px;
              font-size: 14px;
              font-family: PingFang-SC-Regular;
              font-weight: 400;
              color: #333333;

              &:first-child {
                width: 273px;
              }

              &:nth-child(2) {
                width: 160px;
              }

              &:nth-child(3) {
                width: 162px;
              }

              &:nth-child(4) {
                width: 167px;
              }

              &:last-child {
                width: 112px;
              }
            }
          }
        }
      }
    }

    .page-wrap {
      width: 100%;
      display: flex;
      justify-content: center;

      #kkpager {
        padding: 0;

        a,
        span.curr {
          text-align: center;
          width: 32px;
          height: 32px;
          font-size: 16px;
          margin-top: 0;
        }

        span.disabled {
          text-align: center;
          width: 32px;
          height: 32px;
          font-size: 16px;
        }

        span.disabled {
          text-align: center;
          width: 32px;
          height: 32px;
          font-size: 16px;
        }

        .spanDot {
          width: 32px;
          height: 32px;
          box-sizing: border-box;
          border-radius: 2px;
          font-size: 16px;
          line-height: 26px;
          color: #3e4750 !important;
          background: none !important;
          display: inline-block;
          cursor: pointer;
          border: 1px solid rgba(121, 102, 255, 0.6) !important;
          text-align: center;
        }
      }
    }

    .concat-content {
      margin-top: 190px;

      font: {
        size: 14px;
        family: Source Han Sans CN;
        weight: 400;
      }

      line-height: 20px;
      color: #000;
    }

    .concat-qrcode {
      width: 122px;
      height: 122px;
      margin-top: 20px;
    }

    .copyright-header {
      width: 100%;
      height: 84px;
      position: relative;

      &:after {
        display: block;
        content: "";
        width: 64px;
        height: 6px;
        background: rgba(205, 198, 255, 1);
        position: absolute;
        top: 57px;
        left: 0px;
        z-index: 1;
      }

      .copyright-title {
        height: 25px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(51, 51, 51, 1);
        line-height: 25px;
        position: absolute;
        top: 40px;
        left: 0px;
        z-index: 9;
      }
    }
  }

  .banquan-dialog {
    background: rgba(0, 0, 0, .5);

    .modal {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 514px;
      height: max-content;
      background: #fff;
      border-radius: 8px;
      font-family: PingFangSC-Light;
      z-index: 1011;
    }
  }

  .edit-img {
    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .img-info {
      margin: 25px auto 29px;
      width: 100%;
      padding: 0 48px;
      @include flex(center, space-between);

      .img-l {
        width: 200px;
        height: 200px;
        background: #F6F6F6;
      }

      .img-r {
        width: 200px;

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1D2020;
          line-height: 20px;
          margin-bottom: 24px;
          text-align: center;
        }

        div {
          width: 112px;
          height: 112px;
          margin: 0 auto;
          background: #F6F6F6;
          border-radius: 59px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #C0C0C0;
          line-height: 112px;
          text-align: center;
        }
      }
    }

    .img-btn {
      width: 100%;
      padding: 0 48px;
      @include flex(center, space-between);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 40px;
      text-align: center;

      div {
        width: 200px;
        height: 42px;
        cursor: pointer;
        border-radius: 4px;
      }

      .img-l {
        border: 1px solid #2C86FF;
        color: #2C86FF;
      }

      .img-r {
        color: #FFFFFF;
        background: #2C86FF;
      }
    }

    .tip {
      margin-top: 9px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #C0C0C0;
      line-height: 19px;
      padding-left: 48px;
      padding-bottom: 36px;
    }
  }
}
</style>
