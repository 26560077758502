<template>
  <!-- 个人中心 - 账户信息 - 用户头像-->
  <div class="info">
    <div class="banquan-dialog">
      <div class="modal edit-img">
        <div class="title">修改头像<i class="iconfont icon-close" @click="$parent.showEditImg = false"></i></div>
        <div class="img-info">
          <div class="img-l imageBox" @click="getimgurl">
            <div class="thumbBox"></div>
            <div class="spinner" style="display: none;"></div>

          </div>
          <div class="img-r">
            <p>头像预览</p>
            <img :src="preview" alt="" v-show="preview" class="preview-el">
            <div v-if="!cropper">未上传</div>
          </div>
          <div class="img-zoom">
            <i class="iconfont icon-quchu" @click="zoomOut"></i>
            <div class="zoom-el">
              <div></div>
            </div>
            <i class="iconfont icon-tianjia" @click="zoomIn"></i>
          </div>
        </div>
        <div class="img-btn">
          <div class="img-l">上传图片<input type="file" @change="fileUpload" ref="fileEl"></div>
          <div class="img-r" @click="saveImg">保存头像</div>
        </div>
        <div class="tip">
          <p>· 图片格式为jpg、jpeg、png</p>
          <p>· 大小不超过2M</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "@/config/axios/api";
import $ from "jquery"
import '@/config/static/cropbox'

export default {
  name: "Info",
  props: ['url'],
  data () {
    return {
      preview: '',
      cropper: '',
      cropperOptions: {
        thumbBox: '.thumbBox',
        zoomEl: '.zoom-el',
        previewEl: '.preview-el',
        imgSrc: ''
      }
    };
  },
  components: {

  },
  created () {
    this.getBase64();
  },
  methods: {
    // 获取base64
    getBase64 () {
      let that = this;
      //传入图片路径，返回base64
      function getBase64Image (img, width, height) {
        //width、height调用时传入具体像素值，控制大小 ,不传则默认图像大小
        var canvas = document.createElement("canvas");
        canvas.width = width ? width : img.width;
        canvas.height = height ? height : img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        var dataURL = canvas.toDataURL();
        that.preview = dataURL;
        that.cropperOptions.imgSrc = dataURL;

        that.cropper = $('.imageBox').cropbox(that.cropperOptions)
        return dataURL;
      }
      var image = new Image();
      image.crossOrigin = "";
      image.src = this.url;
      var deferred = $.Deferred();
      if (this.url) {
        image.onload = function () {
          deferred.resolve(getBase64Image(image, image.width, image.height)); //将base64传给done上传处理
        };
        return deferred.promise(); //问题要让onload完成后再return sessionStorage['imgTest']
      }
    },
    // 文件上传
    fileUpload () {
      // 判断图片类型
      var path = this.$refs.fileEl.value;
      if (!path) return;
      let that = this;
      // 判断图片大小
      var file = this.$refs.fileEl.files[0]
      let extStart = path.lastIndexOf('.')
      let ext = path.substring(extStart, path.length).toLowerCase()
      if (ext !== '.jpg' && ext !== '.jpeg' && ext !== '.png') {
        this.$message.error('请选择格式为jpg、jpeg、png的图片')
        return false
      }
      if (file.size > 2 * 1024 * 1024) {
        this.$message.error('图片大小不要超过2M')
        return false
      } else {
        var reader = new FileReader()
        reader.onload = function (e) {
          that.cropperOptions.imgSrc = e.target.result;
          that.cropper = $('.imageBox').cropbox(that.cropperOptions);
        }
        reader.readAsDataURL(file)
      }
    },
    getimgurl () {
      if (this.cropper) {
        this.preview = this.cropper.getDataURL()
      }
    },
    // 缩小
    zoomOut () {
      if (this.cropper) {
        this.cropper.zoomOut()
        this.getimgurl()
      }
    },
    // 放大
    zoomIn () {
      if (this.cropper) {
        this.cropper.zoomIn()
        this.getimgurl()
      }
    },
    // 保存头像
    async saveImg () {
      let uploadRes = await User.uploadFile({ base: this.cropper.getDataURL(), type: 'privateUser' });
      if (uploadRes.status == 1) {
        let res = await User.editHead({ head: uploadRes.data.pubUrl });
        if (res.status == 1) {
          this.$parent.showEditImg = false;
          this.$message.success('修改成功');
          this.$parent.getInfo();
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;
  }
}

.edit-img {
  .title {
    width: 100%;
    height: 54px;
    border-bottom: 1px solid rgba(224, 226, 234, 0.66);
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1D2020;
    text-align: center;
    line-height: 54px;
    position: relative;

    i {
      font-size: 14px;
      color: #05061A;
      float: right;
      cursor: pointer;
      position: absolute;
      right: 23px;
    }
  }

  .img-info {
    margin: 25px auto 29px;
    width: 100%;
    padding: 0 48px;
    position: relative;
    @include flex(center, space-between);

    .img-l {
      width: 200px;
      height: 200px;
      background: #F6F6F6;
      overflow: hidden;
      cursor: move;
      position: relative;

      .thumbBox {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 134px;
        height: 134px;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        box-shadow: 0 0 0 1000px rgba(29, 32, 32, 0.5);
        background: none repeat scroll 0% 0% transparent;
      }

    }

    .img-zoom {
      @include flex(center, space-between);
      width: 200px;
      position: absolute;
      bottom: -21px;
      left: 48px;

      .icon-quchu,
      .icon-tianjia {
        color: rgba(0, 0, 0, .3);
        font-size: 14px;
        cursor: pointer;
      }

      .zoom-el {
        width: 160px;
        height: 10px;
        background: #F0F0F0;
        border-radius: 5px;
        position: relative;

        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 0%;
          height: 10px;
          background: #2C86FF;
          border-radius: 5px;
        }
      }
    }

    .img-r {
      width: 200px;
      text-align: center;

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;
        margin-bottom: 24px;
        text-align: center;
      }

      div {
        width: 112px;
        height: 112px;
        margin: 0 auto;
        background: #F6F6F6;
        border-radius: 59px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #C0C0C0;
        line-height: 112px;
        text-align: center;
      }

      img {
        width: 112px;
        height: 112px;
        border-radius: 50%;
      }
    }
  }

  .img-btn {
    width: 100%;
    padding: 0 48px;
    @include flex(center, space-between);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 40px;
    text-align: center;

    div {
      width: 200px;
      height: 42px;
      cursor: pointer;
      border-radius: 4px;
    }

    .img-l {
      border: 1px solid #2C86FF;
      color: #2C86FF;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
      }
    }

    .img-r {
      color: #FFFFFF;
      background: #2C86FF;
    }
  }

  .tip {
    margin-top: 9px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #C0C0C0;
    line-height: 19px;
    padding-left: 48px;
    padding-bottom: 36px;
  }
}
</style>
