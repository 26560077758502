<template>
  <!-- 个人中心 - 账户信息 - 绑定手机号-->
  <div class="banquan-dialog">
    <div class="modal edit-img">
      <div class="title">绑定手机号<i class="iconfont icon-close" @click="$parent.showBindPhone = false"></i></div>
      <div class="form">
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" autocomplete="off">
          <el-form-item label="" prop="mobile" class="row">
            <el-input type="text" v-model="ruleForm.mobile" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="vcode" class="row">
            <el-input type="text" v-model="ruleForm.vcode" placeholder="请输入验证码"></el-input>
            <img :src="imgcodeurl" alt="验证码" ref="imgEl" @click.stop="$refs.imgEl.src = imgcodeurl + getRandom()">
          </el-form-item>
          <el-form-item label="" prop="vmcode" class="row">
            <el-input type="text" placeholder="请输入短信验证码" v-model="ruleForm.vmcode" autocomplete="new-password">
            </el-input>
            <div class="send" @click="sendCode" v-if="second <= 0">获取验证码</div>
            <div class="send active" v-else>({{second}}秒)重新发送</div>
          </el-form-item>
          <div class="row" style="margin-bottom: 14px">
            <div class="submit" @click="nextStep()">确认绑定</div>
          </div>
        </el-form>
        <div class="tip">无法收到验证码？<a href="//wpa.qq.com/msgrd?v=3&uin=1839445785&site=qq&menu=yes"
            target="_blank">联系客服</a></div>
      </div>
    </div>
  </div>
</template>
<script>
import { User, Login } from "@/config/axios/api";

export default {
  name: "Info",
  props: ['toEditPass'],
  data () {
    var validateMobile = (rule, value, callback) => {
      if (!this.staticFunc.checkMobile(value)) {
        callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      loading: false,
      second: 0,
      imgcodeurl: (process.env.NODE_ENV === "development" ? '/api' : process.env.VUE_APP_BaseUrl) + this.getImgCodeUrl + '?_=',
      ruleForm: {
        mobile: "",
        vmcode: "", // 手机验证码
        vcode: "",   //图片验证码
      },
      rules: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validateMobile, trigger: "blur" },
        ],
        vcode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        vmcode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ]
      },
    };
  },
  components: {

  },
  created () {
  },
  methods: {
    getRandom () {
      return 10000 + Math.round(Math.random() * (99999 - 10000))
    },
    sendCode () {
      let validateList = [];
      this.$refs['ruleForm'].validateField(['mobile', 'vcode'], (valid) => {
        validateList.push(valid)
      })
      if (validateList.every((item) => item === "")) {
        Login.registerSms({ mobile: this.ruleForm.mobile, vcode: this.ruleForm.vcode }).then(res => {
          if (res.code == 'S') {
            this.second = 60;
            let smsInterval = setInterval(() => {
              this.second--;
              if (this.second <= 0) {
                clearInterval(smsInterval)
              }
            }, 1000)
          } else {
            this.$refs.imgEl.src = this.imgcodeurl + this.getRandom()
          }
        })
      }

    },
    nextStep () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.loading) return;
          this.loading = true;
          User.bindmobile(this.ruleForm).then(res => {
            this.loading = false;
            if (res.code == 'S') {
              this.$parent.getInfo();
              this.$parent.showBindPhone = false;
              if (this.toEditPass) {
                this.$parent.showEditPass = true;
              } else {
                this.$message.success('绑定成功');
              }
            }
          })
        }
      })

    }
  }
};
</script>
<style scoped lang="scss">
.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;

    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .el-steps {
      margin: 25px 0 12px;

      /deep/ .el-step {
        .el-step__head {
          .el-step__line {
            background: #E0E2EA;
            width: 90px;
            left: 75%;
            right: auto;
            top: 15px;
          }

          .el-step__icon {
            background: #FFFFFF;
            border: 1px solid #DEDEDD;
            width: 32px;
            height: 32px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(5, 6, 26, 0.46);
            line-height: 32px;
            text-align: center;
          }

          &.is-finish {
            .el-step__icon {
              background: #2C86FF;
              border: none;
              color: #FFFFFF;

            }
          }
        }

        .el-step__main {
          margin-top: 7px;

          .el-step__title {
            font-size: 14px;

            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgb(114, 116, 116);
            line-height: 20px;

            &.is-finish {
              color: #1D2020;
              font-weight: 500;
              font-family: PingFangSC-Medium, PingFang SC;
            }
          }
        }
      }

    }

    .form {
      padding: 25px 48px 40px;
      width: 100%;

      .row {
        width: 100%;
        margin-bottom: 18px;
        @include flex(center, space-between);

        /deep/ .el-form-item__content {
          width: 100%;
          @include flex(center, space-between);
          height: 46px;

          .el-input__inner {
            width: 100%;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #DEDEDD;
            padding-left: 23px;

            &:hover,
            &:focus {
              border: 1px solid $activeColor;
            }
          }

          .el-input.is-disabled {
            .el-input__inner {
              background: #F1F1F1;
              cursor: not-allowed;
              border: 1px solid #DEDEDD;

              &:hover {
                border: 1px solid #DEDEDD;
              }
            }
          }
        }


        img {
          margin-left: 12px;
          width: 114px;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #EBECF1;
          cursor: pointer;
          flex-shrink: 0;
        }

        .send {
          flex-shrink: 0;
          cursor: pointer;
          margin-left: 12px;
          width: 114px;
          height: 46px;
          background: rgba(52, 171, 255, 0.04);
          border-radius: 4px;
          border: 1px solid #EBECF1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C86FF;
          line-height: 44px;
          text-align: center;
        }

        .submit {
          width: 100%;
          height: 46px;
          cursor: pointer;
          background: #2C86FF;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
        }
      }

      .tip {
        margin-top: 24px;
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;

        a {
          color: $activeColor;
        }
      }
    }

    .success {
      padding-top: 95px;
      padding-bottom: 178px;
      text-align: center;

      img {
        width: 62px;
        height: 62px;
      }

      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;
        margin: 14px 0 24px;
      }

      div {
        width: 210px;
        height: 46px;
        margin: 0 auto;
        background: #2C86FF;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 46px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>
