<template>
  <!-- 个人中心 - 账户信息 - 修改密码-->
  <div class="banquan-dialog">
    <div class="modal edit-img">
      <div class="title">修改密码<i class="iconfont icon-close" @click="$parent.showEditPass = false"></i></div>
      <div class="form">
        <el-form :rules="rules" :model="ruleForm" ref="ruleForm" autocomplete="off">
          <el-form-item label="" prop="oldpassword" class="row">
            <el-input :type="showOldPass ? 'text' : 'password'" v-model="ruleForm.oldpassword" placeholder="请输入旧密码">
            </el-input>
            <i class="iconfont mima" :class="showOldPass ? 'icon-xianshimima' : 'icon-yincang'"
              @click.stop="showOldPass = !showOldPass"></i>
          </el-form-item>
          <el-form-item label="" prop="newpassword" class="row">
            <el-input :type="showPass ? 'text' : 'password'" placeholder="请输入新密码" v-model="ruleForm.newpassword"
              autocomplete="new-password"></el-input>
            <i class="iconfont mima" :class="showPass ? 'icon-xianshimima' : 'icon-yincang'"
              @click.stop="showPass = !showPass"></i>
          </el-form-item>
          <el-form-item label="" prop="repassword" class="row">
            <el-input :type="showPass1 ? 'text' : 'password'" placeholder="请重新输入新密码" v-model="ruleForm.repassword">
            </el-input>
            <i class="iconfont mima" :class="showPass1 ? 'icon-xianshimima' : 'icon-yincang'"
              @click.stop="showPass1 = !showPass1"></i>
          </el-form-item>
          <el-form-item label="" prop="vcode" class="row">
            <el-input type="text" v-model="ruleForm.vcode" placeholder="请输入右侧验证码"></el-input>
            <img :src="imgcodeurl" alt="验证码" ref="imgEl" @click.stop="$refs.imgEl.src = imgcodeurl + getRandom()">
          </el-form-item>
          <div class="row" style="margin-bottom: 14px">
            <div class="submit" @click="editPass">确认修改</div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { User } from "@/config/axios/api";

export default {
  name: "Info",
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        var reg = /^[A-Za-z0-9]{6,16}$/;
        if (!reg.test(this.ruleForm.newpassword)) {
          callback(new Error("新密码须由6-16位数字或大小写字母组成"));
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.ruleForm.newpassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      showOldPass: false,
      showPass: false,
      showPass1: false,
      second: 0,
      imgcodeurl: (process.env.NODE_ENV === "development" ? '/api' : process.env.VUE_APP_BaseUrl) + this.getImgCodeUrl + '?_=',
      ruleForm: {
        vcode: "",   //图片验证码
        newpassword: "",
        repassword: "",
        oldpassword: '',
      },
      rules: {
        oldpassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        vcode: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
        newpassword: [{ validator: validatePass, trigger: "blur" }],
        repassword: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  components: {

  },
  created () {
  },
  methods: {
    getRandom () {
      return 10000 + Math.round(Math.random() * (99999 - 10000))
    },
    editPass () {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.loading) return;
          this.loading = true;
          User.editPass(this.ruleForm).then(res => {
            this.loading = false;
            if (res.code == 'S') {
              this.$message.success('修改成功');
              this.$parent.getInfo();
              this.$parent.showEditPass = false;
            } else {
              this.$refs.imgEl.src = this.imgcodeurl + this.getRandom()
            }
          })
        }
      })
    }
  }
};
</script>
<style scoped lang="scss">
.banquan-dialog {
  background: rgba(0, 0, 0, .5);

  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 514px;
    height: max-content;
    background: #fff;
    border-radius: 8px;
    font-family: PingFangSC-Light;
    z-index: 1011;

    .title {
      width: 100%;
      height: 54px;
      border-bottom: 1px solid rgba(224, 226, 234, 0.66);
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1D2020;
      text-align: center;
      line-height: 54px;
      position: relative;

      i {
        font-size: 14px;
        color: #05061A;
        float: right;
        cursor: pointer;
        position: absolute;
        right: 23px;
      }
    }

    .form {
      padding: 25px 48px 30px;
      width: 100%;

      .row {
        width: 100%;
        margin-bottom: 18px;
        @include flex(center, space-between);

        /deep/ .el-form-item__content {
          width: 100%;
          @include flex(center, space-between);
          height: 46px;

          .el-input__inner {
            width: 100%;
            height: 46px;
            border-radius: 4px;
            border: 1px solid #DEDEDD;
            padding-left: 23px;

            &:hover,
            &:focus {
              border: 1px solid $activeColor;
            }
          }
        }

        img {
          margin-left: 12px;
          width: 114px;
          height: 46px;
          border-radius: 4px;
          border: 1px solid #EBECF1;
          cursor: pointer;
          flex-shrink: 0;
        }

        .mima {
          position: absolute;
          right: 20px;
          cursor: pointer;
          color: #333333;
          opacity: 0.4;
        }

        .send {
          flex-shrink: 0;
          cursor: pointer;
          margin-left: 12px;
          width: 114px;
          height: 46px;
          background: rgba(52, 171, 255, 0.04);
          border-radius: 4px;
          border: 1px solid #EBECF1;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2C86FF;
          line-height: 44px;
          text-align: center;
        }

        .submit {
          width: 100%;
          height: 46px;
          cursor: pointer;
          background: #2C86FF;
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 44px;
          text-align: center;
        }
      }

      .tip {
        margin-top: 24px;
        text-align: center;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1D2020;
        line-height: 20px;

        a {
          color: $activeColor;
        }
      }
    }
  }
}
</style>
